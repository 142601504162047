/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoggingInterceptorService implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const start = Date.now();

    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const elapsed = Date.now() - start;

          console.groupCollapsed('Fetch:', req.method, req.url, `${elapsed}ms`);
          console.log('%cELAPSED\t\t:', 'color: #885544', `${elapsed}ms`);
          console.log('%cMETHOD\t\t:', 'color: #449900', req.method);
          console.log('URL\t\t\t:', req.urlWithParams);
          console.log(
            '\t\t\t>',
            '[Language]\t\t:' + req.headers.get('Language')
          );
          console.log(
            '\t\t\t>',
            '[Authorization]\t:' + req.headers.get('Authorization')
          );
          console.log('%cBODY\t\t:', 'color: #d2691e', req.body);
          console.log('%cRESPONSE\t:', 'color: #0099ff', event.body);
          console.groupEnd();
        }
      })
    );
  }
}
