/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoint } from '../configs/endpoint.config';
import { TokenGenerateRequest, TokenGenerateResponse } from '../models';
import { CoreFetch } from './core/core';
@Injectable({ providedIn: 'root' })
export class WebAuthFetchService extends CoreFetch {
  constructor() {
    super();
  }

  public generate(data: TokenGenerateRequest): Observable<any> {
    return this.post<TokenGenerateResponse>(Endpoint.Auth.Generate, data);
  }
}
