import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { NgRxState, Action } from '../../modules';
import { TokenGenerate, UserInfoState } from './../../models';

const initialState = {
  username: '',
  access_token: undefined,
  authorizationCode: undefined,
};
@Injectable({ providedIn: 'root' })
export class UserInfoStore extends ComponentStore<UserInfoState> {
  static initialState: UserInfoState = initialState;

  constructor(private ngrxStore: Store<NgRxState>) {
    super(initialState);
  }

  readonly authorizationCode$ = this.select((state) => state.authorizationCode);
  readonly username$ = this.select((state) => state.username);
  readonly sessionId$ = this.select((state) => state.access_token);

  readonly initUserInfo = this.updater(
    (state, tokenGenerate: TokenGenerate) => {
      this.ngrxStore.dispatch(
        Action.UserAction.SetUserInfo({ ...state, userInfo: tokenGenerate })
      );
      return {
        ...state,
        username: tokenGenerate.username,
        access_token: tokenGenerate.access_token,
      };
    }
  );

  readonly setAuthorizationCode = this.updater(
    (state, authorizationCode: string | undefined) => {
      this.ngrxStore.dispatch(
        Action.UserAction.SetAuthCode({ ...state, authCode: authorizationCode })
      );
      return {
        ...state,
        authorizationCode: authorizationCode,
      };
    }
  );
}
