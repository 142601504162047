import { Injectable } from '@angular/core';
import { MISSION_SCREEN, MISSION_STATUS, MISSION_TYPE } from '../configs';
import { CampaignBanner, MissionTracking } from '../models';
import { COUPON_STATUS } from 'libs/common/src/lib/configs/coupon.config';
import { Subject } from 'rxjs';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  public isMissionExpired = false;
  public isNoPermission = false;
  public pageIndex$ = new Subject<number>();
  public pageIndex: number | undefined = 1;
  public isMissionDisplayExpired = false;

  public isModeDisabled(campaign?: CampaignBanner) {
    return this.isFullRedeem(campaign) && !this.isCollectedReward(campaign);
  }

  public isDisabled(campaign?: CampaignBanner, screen?: MISSION_SCREEN) {
    if (this.isTransitOrWalletTopup(campaign)) {
      if (
        screen === MISSION_SCREEN.LANDING &&
        campaign?.campaignType === MISSION_TYPE.TRANSIT
      ) {
        this.pageIndex =
          campaign?.tracking?.couponList
            ?.filter((cl) => cl.type !== null)
            .slice(-1)[0]?.index ?? 1;
        return this.isPageGrayOutMultiple(campaign);
      }
      return this.isPageGrayOutMultiple(campaign);
    }
    return this.isFullRedeem(campaign) && !this.isCollectedReward(campaign);
  }

  public isPageGrayOutMultiple(campaign?: CampaignBanner): boolean {
    const currentCardCouponStatus: COUPON_STATUS | null =
      campaign?.campaignType === MISSION_TYPE.TRANSIT
        ? this.getCurrentCardCouponStatus(campaign)
        : this.getCouponStatusWallet(campaign);
    if (currentCardCouponStatus !== null) {
      return (
        ((currentCardCouponStatus == COUPON_STATUS.RUNOUT ||
          currentCardCouponStatus == COUPON_STATUS.RECEIVED) &&
          campaign?.tracking?.pendingAvailableCouponCount == 0 &&
          !campaign.tracking?.missionCompleted) ||
        false
      );
    }

    return false;
  }
  public getCurrentCardCouponStatus(campaign?: CampaignBanner) {
    const couponList = campaign?.tracking?.couponList || [];
    if (this.pageIndex !== undefined) {
      let status = couponList.find((cl) => cl.index === this.pageIndex)?.status;

      if (
        status == COUPON_STATUS.RECEIVED &&
        (this.pageIndex || 0) < (campaign?.tracking?.couponList?.length || 0)
      ) {
        this.pageIndex++;
        status = couponList.find((cl) => cl.index === this.pageIndex)?.status;
      }
      return typeof status === 'number' ? status : null;
    }
    return null;
  }

  public getCouponStatusWallet(campaign?: CampaignBanner) {
    return campaign != undefined
      ? campaign.tracking.couponList.filter((cl) => cl.received === false)
          .length > 0 || false
        ? campaign.tracking.couponList.filter((cl) => cl.received === false)[0]
            .status
        : null
      : null;
  }

  public isHiddenButton(campaign?: CampaignBanner) {
    return this.isMissionCompleted(campaign) || this.isDisabled(campaign);
  }

  public isFullRedeem(campaign?: CampaignBanner) {
    return (campaign?.rewardRedeemedPercent || 0) >= 100;
  }

  public isCollectedReward(campaign?: CampaignBanner) {
    return campaign?.tracking?.couponCollectedFlag || false;
  }

  public isMissionCompleted(campaign?: CampaignBanner) {
    if (this.isTransitOrWalletTopup(campaign)) {
      return campaign?.tracking.missionCompleted;
    }
    return campaign?.bannerHeader?.bannerStatus === MISSION_STATUS.COMPLETED;
  }

  public isAllowAccess() {
    return !this.isMissionExpired && !this.isNoPermission;
  }

  public isQuiz(campaign: CampaignBanner) {
    return (
      campaign?.campaignType === MISSION_TYPE.SPENDING_VALUE &&
      campaign?.bannerHeader?.gameId === 'quiz'
    );
  }

  // Start MOCK campaignName
  public getCampaignName(campaign: CampaignBanner) {
    if (campaign.campaignName === undefined || campaign.campaignName === '') {
      switch (campaign?.campaignType) {
        case MISSION_TYPE.CHECK_IN:
          return 'เช็กอินเป๋าเปย์เฮรับคุ้ม';
        case MISSION_TYPE.SPENDING_COUNT:
          return 'เปย์บิลรับคุ้ม';
        case MISSION_TYPE.SPENDING_VALUE:
          if (this.isQuiz(campaign)) {
            return 'ปริศนาเป๋าเปย์';
          }
          return 'เปย์ร้อยรับคุ้ม';
        case MISSION_TYPE.ACCUMULATE:
          return 'ยิ่งเติมยิ่งคุ้ม';
        default:
          return '';
      }
    }
    return campaign?.campaignName;
  }
  // End MOCK campaignName

  public isTransitOrWalletTopup(campaign?: CampaignBanner) {
    return (
      campaign?.campaignType === MISSION_TYPE.TRANSIT ||
      campaign?.campaignType === MISSION_TYPE.WALLET_TOPUP
    );
  }

  public isAllMissionComplete(campaign?: CampaignBanner): boolean {
    return campaign?.bannerHeader?.bannerStatus === MISSION_STATUS.COMPLETED;
  }

  public isRewardFullyClaimed(campaign?: CampaignBanner): boolean {
    return (
      campaign?.bannerHeader?.bannerStatus === MISSION_STATUS.FULLY_CLAIMED
    );
  }

  public isShowPendingReward(banner: CampaignBanner) {
    return (
      (banner.tracking?.pendingAvailableCouponCount || 0) === 0 ||
      banner.tracking?.missionCompleted
    );
  }

  public isEarnBurnExpired(missionTracking: MissionTracking) {
    const now = dayjs();
    const startDate = dayjs(missionTracking?.startDate);
    const endDate = dayjs(missionTracking?.endDate);
    const displayEndDate = dayjs(missionTracking?.displayEndDate);

    const isActive =
      (now.isAfter(startDate) || now.isSame(startDate)) &&
      now.isBefore(endDate);

    const isExpired =
      (now.isAfter(endDate) && now.isBefore(displayEndDate)) ||
      now.isSame(displayEndDate);

    return isExpired && !isActive;
  }
}
