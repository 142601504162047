/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({ providedIn: 'root' })
export class TransformTool {
  constructor(private sanitizer: DomSanitizer) {}
  public static convertObjectToQueryString(values: any): string {
    if (!values) {
      return '';
    }

    const query = Object.keys(values)
      .map((key) => {
        if (Array.isArray(values[key])) {
          const arrayToString = [];
          for (const value of values[key]) {
            arrayToString.push(key + '=' + value);
          }
          return arrayToString.join('&');
        } else {
          return key + '=' + values[key];
        }
      })
      .join('&');

    if (!query) {
      return '';
    }

    return '?' + query;
  }

  public static groupArrayObjectByKey(items: any[], key: string) {
    return items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );
  }
}
