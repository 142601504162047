import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Endpoint } from '../configs/endpoint.config';
import { ShareInfoRequest, ShareInfoResponse } from '../models';
import { BE_CODE, ERROR_TYPE, MISSION_SCREEN } from '../configs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { CoreFetch } from './core/core';

@Injectable({ providedIn: 'root' })
export class ShareFetchService extends CoreFetch {
  constructor(private errorHandler: ErrorHandlerService) {
    super();
  }

  public info(path: ShareInfoRequest): Observable<ShareInfoResponse> {
    return this.get<ShareInfoResponse>(
      `${Endpoint.Share.Info}/${path.campaignCode}`
    ).pipe(
      map((resp: any) => {
        if (resp.code !== BE_CODE.SUCCESS) {
          this.errorHandler.setError(
            ERROR_TYPE.POPUP,
            {
              screen: MISSION_SCREEN.TRACKING,
              errorMessage: `${resp.error_data?.error_title} ${resp.error_data?.error_message}`,
              code: resp.code,
            },
            resp.error_data
          );
        }
        return resp;
      })
    );
  }
}
