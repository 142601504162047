import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { LoggingInterceptorService } from './core/logging.interceptor.service';
import { EventInterceptorService } from './core/event-interceptor.service';

export const LoggingInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoggingInterceptorService,
  multi: true,
};

export const EventInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: EventInterceptorService,
  multi: true,
};

export * from './web-auth.service';
export * from './mission-tracking-fetch.service';
export * from './banner-fetch.service';
export * from './campaign-fetch.service';
export * from './check-in-fetch.service';
export * from './coupon-fetch.service';
export * from './perx-fetch.service';
export * from './reward-fetch.service';
export * from './validate-fetch.service';
export * from './share-fetch.service';
export * from './leaderboard-fetch.service';
export * from './point-histories-fetch.service';
export * from './tracking-redeem-fetch.service';
