/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { UserInfoStore } from './../../stored';

import { Observable, of as rxjsOf, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Endpoint } from '../../configs/endpoint.config';
import { BE_CODE } from '../../configs/error.config';
import { HttpService } from '../../services/http.service';
import ServiceLocator from '../../services/service-locator.service';

export type CacheOptions = 'cache-first' | 'cache-only' | 'force-fetch';

export abstract class CoreFetch {
  private _http: HttpClient = ServiceLocator.rootInjector?.get(HttpClient);

  protected get<T>(url: string): Observable<T> {
    const apiObj = this._http?.get<T>(url);
    return this.convertResponse(apiObj)?.pipe(
      catchError((error: any) => {
        if (error == 'expired') {
          return this.getRefreshToken(apiObj, url);
        } else {
          return this.convertResponse(apiObj);
        }
      })
    );
  }

  protected post<T>(
    url: string,
    body: any = null,
    header?: any
  ): Observable<T> {
    const apiObj = this._http?.post<T>(url, body, { headers: header });
    return this.convertResponse(apiObj)?.pipe(
      catchError((error: any) => {
        if (error == 'expired') {
          return this.postRefreshToken(apiObj, url, body, header);
        } else {
          return this.convertResponse(apiObj);
        }
      })
    );
  }

  protected put<T>(url: string, body: any = null): Observable<T> {
    const apiObj = this._http.put<T>(url, body);
    const result = this.convertResponse(apiObj);
    return result;
  }

  // protected patch<T>(url: string, body: any = null): Observable<T> {
  //     const apiObj = this._http.patch<T>(url, body);
  //     let result = this.convertResponse(apiObj);
  //     return result;
  // }

  // protected delete<T>(url: string, query: any = null): Observable<T> {
  //     const apiObj = this._http.delete<T>(url);
  //     let result = this.convertResponse(apiObj);
  //     return result;
  // }

  protected getRefreshToken<T>(
    observable: Observable<T>,
    url: string
  ): Observable<T> {
    const httpService = ServiceLocator.rootInjector.get(HttpService);
    const bodyToken: any = {
      webviewToken: httpService.webviewTokenParam || '',
    };
    const apiObjToken = this.post<T>(Endpoint.Auth.Generate, bodyToken);
    return this.refreshTokenMapGet(apiObjToken, url);
  }
  private refreshTokenMapGet<T>(
    observable: Observable<T>,
    url: string
  ): Observable<T> {
    return observable?.pipe(
      switchMap((response: any) => {
        rxjsOf(response);
        const httpService = ServiceLocator.rootInjector.get(HttpService);
        const userInfoStore = ServiceLocator.rootInjector.get(UserInfoStore);
        userInfoStore.initUserInfo(response?.data);
        httpService.verifyToken = response?.data.access_token;
        const apiObj = this._http.get<T>(url);
        return this.convertResponseRefreshToken(apiObj);
      })
    );
  }

  protected postRefreshToken<T>(
    observable: Observable<T>,
    url: string,
    body: any = null,
    header?: any
  ): Observable<T> {
    const httpService = ServiceLocator.rootInjector.get(HttpService);
    const bodyToken: any = {
      webviewToken: httpService.webviewTokenParam || '',
    };
    const apiObjToken = this.post<T>(Endpoint.Auth.Generate, bodyToken);
    return this.refreshTokenMapPost(apiObjToken, url, body, header);
  }
  private refreshTokenMapPost<T>(
    observable: Observable<T>,
    url: string,
    body: any = null,
    header?: any
  ): Observable<T> {
    return observable?.pipe(
      switchMap((response: any) => {
        rxjsOf(response);
        const httpService = ServiceLocator.rootInjector.get(HttpService);
        const userInfoStore = ServiceLocator.rootInjector.get(UserInfoStore);
        userInfoStore.initUserInfo(response?.data);
        httpService.verifyToken = response?.data.access_token;
        const apiObj = this._http.post<T>(url, body, { headers: header });
        return this.convertResponseRefreshToken(apiObj);
      })
    );
  }

  private convertResponse<T>(observable: Observable<T>): Observable<T> {
    return observable?.pipe(
      switchMap((response: any) => {
        const result = rxjsOf(response);
        if (response.code === BE_CODE.EXPIRED) {
          return throwError('expired');
        }
        return result;
      })
    );
  }

  private convertResponseRefreshToken<T>(
    observable: Observable<T>
  ): Observable<T> {
    return observable?.pipe(
      switchMap((response: any) => {
        const result = rxjsOf(response);
        return result;
      })
    );
  }
}
