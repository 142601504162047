import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Endpoint } from '../configs/endpoint.config';
import { CoreFetch } from './core/core';
import { LeaderboardResponse } from '../models/leaderboard-detail.interface';
import {
  PreviousRankPopupRequest,
  PreviousRankPopupResponse,
} from '../models/previous-rank.interface';
import {
  BE_CODE,
  ERROR_TYPE,
  GA_FIREBASE_PARAMS_KEY,
  GA_EVENT_ACTION,
  MISSION_SCREEN,
} from '../configs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LeaderboardFetchService extends CoreFetch {
  constructor(
    private errorHandler: ErrorHandlerService,
    private translateService: TranslateService
  ) {
    super();
  }

  public detail(campaignCode: string): Observable<LeaderboardResponse> {
    return this.get<LeaderboardResponse>(
      Endpoint.Leaderboard.Detail.replace('{campaignCode}', campaignCode)
    ).pipe(
      tap((resp: LeaderboardResponse) => {
        if (resp.code === BE_CODE.NOT_IN_SEGMENT) {
          this.errorHandler.setError(
            ERROR_TYPE.POPUP,
            {
              screen: MISSION_SCREEN.LANDING,
              errorMessage: resp.errorData?.errorMessage || '',
              code: resp.code,
              ga: {
                event: {
                  action: GA_EVENT_ACTION.ERROR,
                  category: MISSION_SCREEN.API_ERROR,
                  label: MISSION_SCREEN.LANDING + '_PopUpPermissionUserAccess',
                },
                firebaseParam: [
                  {
                    key: GA_FIREBASE_PARAMS_KEY.PT_INFORMATION,
                    value: `campaignCode: ${campaignCode}`,
                  },
                ],
              },
            },
            {
              error_title:
                resp.errorData?.errorTitle ||
                this.translateService.instant('ERROR.GENERIC.TITLE'),
              error_message:
                resp.errorData?.errorMessage ||
                this.translateService.instant('ERROR.GENERIC.TEXT'),
            }
          );
        }
      })
    );
  }

  public seenPreviousRank(data: PreviousRankPopupRequest) {
    return this.put<PreviousRankPopupResponse>(
      Endpoint.Leaderboard.PreviousRankPopup.replace(
        '{campaignCode}',
        data.campaignCode
      )
    );
  }
}
