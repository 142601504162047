/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Event } from '../models';
import { KeyValue, Navigate, ShareToFriend } from '../models/js.interface';
import { HttpService } from './http.service';

export interface JavascriptCallback {
  gaTagging(data: any): any;
  onReceiveJSCommand(data: any): any;
  onRetrievePayloadString(data: any): any;
}

declare const campaignWeb: JavascriptCallback;
declare const nativeInterface: JavascriptCallback;
declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class JsInterfaceService {
  constructor(private httpService: HttpService) {}

  public gaTagging(
    event: Event,
    firebaseParam?: KeyValue[],
    trackAppsflyer = false,
    trackGA = true
  ) {
    const data = {
      trackGA,
      ...(trackAppsflyer && { trackAppsflyer }),
      ga: {
        event,
        firebaseParam,
      },
    };

    console.log(data, 'gaTagging');

    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = nativeInterface;
        console.log('isAndroid');
        android.onRetrievePayloadString(body);
      } else {
        window.webkit.messageHandlers.nativeInterface.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
      // handle when open on web
    }
  }

  public shareToFriend(data: ShareToFriend) {
    console.log(data, 'shareToFriend');

    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = campaignWeb;
        console.log('isAndroid');
        android.onReceiveJSCommand(body);
      } else {
        window.webkit.messageHandlers.nativeInterface.postMessage(data);
      }
    } catch (error) {
      console.log('isWeb');
      // handle when open on web
    }
  }

  public navigation(data: Navigate) {
    const finalData: Navigate = {
      ...data,
      content: {
        ...data.content,
        ...(data.content?.destination !== 'BACK' && {
          additionalParams: `${data.content.additionalParams}${
            this.httpService.channel
              ? '&channel=' + this.httpService.channel
              : ''
          }${
            this.httpService.product
              ? '&product=' + this.httpService.product
              : ''
          }`,
        }),
      },
    };
    console.log('navigate ', finalData);
    const body = JSON.stringify(finalData);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = nativeInterface;
        console.log('isAndroid');
        android.onRetrievePayloadString(body);
      } else {
        window.webkit.messageHandlers.nativeInterface.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }
}
