import { MISSION_SCREEN } from './path.config';

export enum NAV_MODE {
  HIDDEN,
  CLEAR,
  NORMAL,
  SAFE_AREA,
}

export const noPullToRefreshScreen = [
  MISSION_SCREEN.TRACKING_DETAIL,
  MISSION_SCREEN.TRACKING_LEADERBOARD_INFO,
  MISSION_SCREEN.TRACKING_LEADERBOARD_REWARDS,
  MISSION_SCREEN.TRACKING_LEADERBOARD_TC,
  MISSION_SCREEN.TRACKING_EARN_AND_BURN_DETAIL,
  MISSION_SCREEN.TRACKING_EARN_AND_BURN_REWARD,
  MISSION_SCREEN.TRACKING_EARN_AND_BURN_TC,
];

export const noSkeletonScreen = [
  MISSION_SCREEN.TRACKING_LEADERBOARD_INFO,
  MISSION_SCREEN.TRACKING_LEADERBOARD_REWARDS,
  MISSION_SCREEN.TRACKING_EARN_AND_BURN_DETAIL,
  MISSION_SCREEN.TRACKING_EARN_AND_BURN_REWARD,
];
