import { LeaderboardMenu } from '../models';
import { ROUTE_PATH } from './path.config';

export enum LEADERBOARD_MENU {
  REWARD = 'TotalRewards',
  PREVIOUS = 'RankingHistory',
  HISTORY = 'ReferralHistory',
  MISSION_HISTORY = 'MissionTrackingHistory',
  INFORMATION = 'MissionInfo',
  TERM_CON = 'T&C',
}

export enum LEADERBOARD_DATASOURCE {
  REFERRAL = 'REFERRAL',
  SPENDING = 'SPENDING',
}

export function LeaderboardConfig(
  datasource: LEADERBOARD_DATASOURCE
): LeaderboardMenu[] {
  return [
    {
      name: LEADERBOARD_MENU.REWARD,
      icon: '/assets/ico/lb-gift.png',
      text: 'SCREEN.LEADERBOARD.MENU.REWARD',
      route: ROUTE_PATH.LEADERBOARD_REWARDS,
    },
    {
      name: LEADERBOARD_MENU.PREVIOUS,
      icon: '/assets/ico/lb-ranking.png',
      text: 'SCREEN.LEADERBOARD.MENU.RANKING',
      route: ROUTE_PATH.LEADERBOARD_PREVIOUS,
    },
    {
      name:
        datasource === LEADERBOARD_DATASOURCE.REFERRAL
          ? LEADERBOARD_MENU.HISTORY
          : LEADERBOARD_MENU.MISSION_HISTORY,
      icon: '/assets/ico/lb-history.png',
      text:
        datasource === LEADERBOARD_DATASOURCE.REFERRAL
          ? 'SCREEN.LEADERBOARD.MENU.HISTORY'
          : 'SCREEN.LEADERBOARD.MENU.MISSION_HISTORY',
      route:
        datasource === LEADERBOARD_DATASOURCE.REFERRAL
          ? ROUTE_PATH.LEADERBOARD_HISTORY
          : ROUTE_PATH.TRACKING_HISTORY,
    },
    {
      name: LEADERBOARD_MENU.INFORMATION,
      icon: '/assets/ico/lb-info.png',
      text: 'SCREEN.LEADERBOARD.MENU.INFORMATION',
      route: ROUTE_PATH.LEADERBOARD_INFO,
    },
    {
      name: LEADERBOARD_MENU.TERM_CON,
      icon: '/assets/ico/lb-t&c.png',
      text: 'SCREEN.LEADERBOARD.MENU.T&C',
      route: ROUTE_PATH.LEADERBOARD_TC,
    },
  ];
}
