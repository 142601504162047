import { TokenGenerate } from './../../../models/token-generate.interface';
import { createAction, props } from '@ngrx/store';

export const SetUserInfo = createAction(
  'SetUserInfo',
  props<{ userInfo: TokenGenerate }>()
);

export const SetAuthCode = createAction(
  'SetAuthCode',
  props<{ authCode: string | undefined }>()
);
