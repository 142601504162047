import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentStore } from '@ngrx/component-store';
import { LeaderboardData, LeaderboardState } from '../../models';
import { Action, NgRxState } from '../../modules';

const initialState: LeaderboardState = {
  leaderboard: undefined,
};

@Injectable({ providedIn: 'root' })
export class LeaderboardStore extends ComponentStore<LeaderboardState> {
  static initialState: LeaderboardState = initialState;

  constructor(private ngrxStore: Store<NgRxState>) {
    super(initialState);
  }

  readonly leaderboard$ = this.select((state) => state.leaderboard);

  readonly setLeaderboard = this.updater(
    (state, leaderboard: LeaderboardData) => {
      this.ngrxStore.dispatch(
        Action.LeaderboardAction.SetLeaderboard({ ...state, leaderboard })
      );
      return { ...state, leaderboard };
    }
  );

  readonly reset = this.updater(() => {
    this.ngrxStore.dispatch(Action.LeaderboardAction.Reset());
    return { ...initialState };
  });
}
