import { Action, createReducer, on } from '@ngrx/store';
import { Reset } from '../actions/leaderboard.action';
import { MissionTrackingAction } from '../actions';
import { MissionTrackingState } from './../../../models';
import { MissionTrackingStore } from '../../components/mission-tracking.store';

const initialState: MissionTrackingState = {
  ...MissionTrackingStore?.initialState,
};

const missionTrackingReducer = createReducer(
  initialState,
  on(
    MissionTrackingAction.SetMissionTrackingList,
    (state, { ...MissionTrackingState }) => ({
      ...state,
      ...MissionTrackingState,
    })
  ),
  on(
    MissionTrackingAction.SetMissionTracking,
    (state, { ...MissionTrackingState }) => ({
      ...state,
      ...MissionTrackingState,
    })
  ),
  on(
    MissionTrackingAction.SetPointBalance,
    (state, { ...PointBalanceState }) => ({
      ...state,
      ...PointBalanceState,
    })
  ),
  on(MissionTrackingAction.SetReward, (state, { ...RewardState }) => ({
    ...state,
    ...RewardState,
  })),
  on(Reset, () => initialState)
);

export function MissionTrackingReducer(
  state: MissionTrackingState | undefined,
  action: Action
) {
  return missionTrackingReducer(state, action);
}
