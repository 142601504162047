export enum GA_EVENT_ACTION {
  MIGRATION = 'Migration',
  CLICK = 'Click',
  SLIDE = 'Slide',
  COMPLETE = 'Complete',
  ERROR = 'Error',
  AUTHENTICATE = 'Authenticate',
  VIEW = 'screen_view',
}

export enum GA_EVENT_CATEGORY {
  LANDING = 'MissionLanding',
  TRACKING = 'MissionTracking',
}

export enum GA_FIREBASE_PARAMS_KEY {
  PT_INFORMATION = 'pt_information',
  PRODUCT_TYPE = 'product_type',
}
