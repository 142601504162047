import { createAction, props } from '@ngrx/store';
import { MissionTracking, Rewards } from '../../../models';

export const SetMissionTrackingList = createAction(
  'SetMissionTrackingList',
  props<{ missionTrackingList: MissionTracking[] }>()
);

export const SetMissionTracking = createAction(
  'SetMissionTracking',
  props<{ missionTracking: MissionTracking }>()
);

export const SetPointBalance = createAction(
  'SetPointBalance',
  props<{ pointBalance: number }>()
);

export const SetReward = createAction(
  'SetReward',
  props<{ reward: Rewards }>()
);

export const Reset = createAction('Reset');
