import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[voiceOverEmoji]',
})
export class VoiceOverEmojiDirective {
  constructor(private elem: ElementRef, private _renderer: Renderer2) {}

  ngAfterViewInit() {
    const isEmoji = this.isEmoji(this.elem.nativeElement.innerText);
    if (isEmoji) {
      const newText = this.replaceEmoji(this.elem.nativeElement.innerText);
      this._renderer.setProperty(this.elem.nativeElement, 'innerHTML', newText);
    }
  }

  private isEmoji(text: string) {
    return /\p{Extended_Pictographic}/u.test(text);
  }

  private replaceEmoji(text: string) {
    const pattern: RegExp = /\p{Extended_Pictographic}/gu;
    const newText: string = text.replace(pattern, (match, index) => {
      return `<span aria-hidden="true">${match}</span>`;
    });
    return newText;
  }
}
