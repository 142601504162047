import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGE_NAME } from '@ptp-campaign-spending/common/configs';

const routers: Routes = [
  {
    path: '',
    data: { animation: PAGE_NAME.LANDING },
    loadChildren: () =>
      import('../views/layouts/mains/mains.module').then((m) => m.MainsModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routers, { scrollPositionRestoration: 'top' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
