import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { Endpoint } from '../configs/endpoint.config';
import { CouponDetailRequest, CouponDetailResponse } from '../models';
import { TransformTool } from '../tools/transform.tool';
import { ErrorHandlerService } from '../services/error-handler.service';
import {
  BE_CODE,
  ERROR_TYPE,
  GA_EVENT_ACTION,
  MISSION_SCREEN,
} from '../configs';
import { CoreFetch } from './core/core';

@Injectable({ providedIn: 'root' })
export class CouponFetchService extends CoreFetch {
  constructor(private errorHandler: ErrorHandlerService) {
    super();
  }

  public detail(query: CouponDetailRequest): Observable<CouponDetailResponse> {
    return this.get<CouponDetailResponse>(
      `${Endpoint.Coupon.Detail}${TransformTool.convertObjectToQueryString(
        query
      )}`
    ).pipe(
      map((resp: any) => {
        if (resp.code !== BE_CODE.SUCCESS) {
          this.errorHandler.setError(
            ERROR_TYPE.POPUP,
            {
              screen: MISSION_SCREEN.TRACKING,
              errorMessage: `${resp.error_data?.error_title} ${resp.error_data?.error_message}`,
              code: resp.code,
              ga: {
                event: {
                  action: GA_EVENT_ACTION.ERROR,
                  category: MISSION_SCREEN.API_ERROR,
                  label: `${resp.code}//${resp.message}`,
                },
                firebaseParam: [],
              },
            },
            resp.error_data
          );
        }
        return resp;
      })
    );
  }
}
