import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentStore } from '@ngrx/component-store';
import { MissionTracking, MissionTrackingState, Rewards } from '../../models';
import { Action, NgRxState } from '../../modules';

const initialState: MissionTrackingState = {
  missionTrackingList: undefined,
  missionTracking: undefined,
  pointBalance: undefined,
  reward: undefined,
};

@Injectable({ providedIn: 'root' })
export class MissionTrackingStore extends ComponentStore<MissionTrackingState> {
  static initialState: MissionTrackingState = initialState;

  constructor(private ngrxStore: Store<NgRxState>) {
    super(initialState);
  }

  readonly missionTrackingList$ = this.select(
    (state) => state.missionTrackingList
  );
  readonly missionTracking$ = this.select((state) => state.missionTracking);
  readonly pointBalance$ = this.select((state) => state.pointBalance);
  readonly reward$ = this.select((state) => state.reward);

  readonly setMissionTrackingList = this.updater(
    (state, missionTrackingList: MissionTracking[]) => {
      this.ngrxStore.dispatch(
        Action.MissionTrackingAction.SetMissionTrackingList({
          ...state,
          missionTrackingList,
        })
      );
      return { ...state, missionTrackingList };
    }
  );

  readonly setMissionTracking = this.updater(
    (state, missionTracking: MissionTracking) => {
      this.ngrxStore.dispatch(
        Action.MissionTrackingAction.SetMissionTracking({
          ...state,
          missionTracking,
        })
      );
      return { ...state, missionTracking };
    }
  );

  readonly setPointBalance = this.updater((state, pointBalance: number) => {
    this.ngrxStore.dispatch(
      Action.MissionTrackingAction.SetPointBalance({
        ...state,
        pointBalance,
      })
    );
    return { ...state, pointBalance };
  });

  readonly setReward = this.updater((state, reward: Rewards) => {
    this.ngrxStore.dispatch(
      Action.MissionTrackingAction.SetReward({
        ...state,
        reward,
      })
    );
    return { ...state, reward };
  });

  readonly reset = this.updater(() => {
    this.ngrxStore.dispatch(Action.LeaderboardAction.Reset());
    return { ...initialState };
  });
}
