/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, createReducer, on } from '@ngrx/store';
import { UserInfoState } from '../../../models/user-info.interface';
import { UserInfoStore } from '../../components/user-info.store';
import { UserAction } from '../actions';

const initialState: UserInfoState = { ...UserInfoStore.initialState };

const userReducer = createReducer(
  initialState,
  on(UserAction.SetAuthCode, (state, { ...UserInfoState }) => ({
    ...state,
    ...UserInfoState,
  }))
);

export function UserReducer(state: UserInfoState | undefined, action: Action) {
  return userReducer(state, action);
}
