export enum MISSION_TYPE {
  CHECK_IN = 'I',
  SPENDING_VALUE = 'V',
  SPENDING_COUNT = 'C',
  SPENDING_VALUE_QUIZ = 'Q',
  ACCUMULATE = 'A',
  TRANSIT = 'T',
  WALLET_TOPUP = 'W',
  SPENDING_REGISTER = 'R',
  LEADERBOARD = 'L',
  EARN_AND_BURN = 'EARN_AND_BURN',
}

export enum MISSION_SUBTYPE {
  ARTWORK = 'ARTWORK',
  DEFAULT = 'DEFAULT',
}

export enum MISSION_SPENDING_VALUE {
  SPENDING_VALUE = 'V',
  SPENDING_VALUE_QUIZ = 'Q',
}

export enum IMG_STYLE {
  ANIMATION = 'A', // lottie
  IMAGE = 'I', // png
}

export enum MISSION_STATUS {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
  FULLY_CLAIMED = 3,
}

export enum DISPLAY_DATE_TYPE {
  EXPIRE_DATE,
  EXPIRE_SOON,
  EXPIRE_TODAY,
  EXPIRE_DATE_TIME,
  EXPIRED,
  INCOMING,
}

export enum REWARD_STATUS {
  NOT_STARTED,
  AVAILABLE,
  FULL_REDEEM,
}

export enum REWARD_TYPE {
  COUPON = 'coupon',
  REWARD = 'reward',
  POINT = 'point',
}

export enum OUTCOME_MODE {
  EARN = 'earn',
  SPENT = 'spent',
}

export enum OUTCOME_TYPE {
  REWARD = 'reward',
  POINT = 'points',
}

export enum HISTORY_CATEGORY {
  COUPON = 'coupon',
  RECEIVE = 'receive',
  USE = 'use',
  CHECK_IN_DONE = 'checkin_complete',
  CHECK_IN = 'checkin',
  UNIT = 'unit',
  TRANSACTION = 'transaction',
  TRANSIT = 'transit',
  TRANSIT_DONE = 'transit_complete',
  REVERT = 'revert',
}

export enum SOF_TYPE {
  PW = 'PW',
  PC = 'PC',
}

export enum NAVIGATE_REQUIRED_ACTION {
  PAYBILL = 'PAY_BILL',
  TOPUP = 'TOP_UP',
  SCANQR = 'SCAN_QR',
  PCACTIVATE = 'PC_ACTIVATE',
  NONAVIGATION = 'NO_NAVIGATION',
}

export enum PROGRESS_TYPE {
  REGISTER_PW = 'REGISTER_PW',
  REGISTER_PW_TOP_UP = 'REGISTER_PW_TOP_UP',
  REGISTER = 'REGISTER',
  ACTIVATE = 'ACTIVATE',
  REGISTER_ACTIVATE = 'REGISTER_ACTIVATE',
  REGISTER_SPENDING = 'REGISTER_SPENDING',
  ACTIVATE_SPENDING = 'ACTIVATE_SPENDING',
  REGISTER_ACTIVATE_SPENDING = 'REGISTER_ACTIVATE_SPENDING',
}

export enum POPUP_TYPE {
  REGISTER = 0,
  MARKETING = 1,
}
