import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  PointHistoriesRequest,
  PointHistoriesResponse,
} from '../models/point-histories.interface';
import { Endpoint } from '../configs/endpoint.config';
import { CoreFetch } from './core/core';
import { TransformTool } from '../tools/transform.tool';
import { BE_CODE, ERROR_TYPE, MISSION_SCREEN } from '../configs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PointHistoriesFetchService extends CoreFetch {
  constructor(
    private errorHandler: ErrorHandlerService,
    private translateService: TranslateService
  ) {
    super();
  }
  public fetch(
    query: PointHistoriesRequest
  ): Observable<PointHistoriesResponse> {
    return this.get<PointHistoriesResponse>(
      `${
        Endpoint.MissionTracking.PointHistories
      }${TransformTool.convertObjectToQueryString(query)}`
    ).pipe(
      map((resp: PointHistoriesResponse) => {
        if (resp.code !== BE_CODE.SUCCESS) {
          this.errorHandler.setError(
            ERROR_TYPE.FULLSCREEN_API,
            {
              screen: MISSION_SCREEN.TRACKING_HISTORY,
              errorMessage: resp.errorData?.errorMessage || '',
              code: resp.code,
            },
            {
              error_title:
                resp.errorData?.errorTitle ||
                this.translateService.instant('ERROR.GENERIC.TITLE'),
              error_message:
                resp.errorData?.errorMessage ||
                this.translateService.instant('ERROR.GENERIC.TEXT'),
            }
          );
        }
        return resp;
      })
    );
  }
}
