/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Action, NgRxState } from '@ptp-campaign-spending/common';
import {
  RewardReceiveRequest,
  RewardStatus,
} from '@ptp-campaign-spending/common/models';

import {
  CampaignBanner,
  LandingDetail,
} from '../../models/banner-list.interface';
import {
  Campaign,
  HistoryScreen,
} from '../../models/campaign-detail.interface';
import { CampaignTracking } from '../../models/campaign-statement.interface';
import { CampaignState } from '../../models/campaign.interface';

const initalState: CampaignState = {
  landing: undefined,
  campaigns: [],
  selectedBanner: undefined,
  campaign: undefined,
  statement: undefined,
  rewardReceiveReq: undefined,
  rewardStatus: undefined,
  couponId: undefined,
  perxToken: undefined,
  historyScreen: undefined,
  getDefaultCoupon: undefined,
  campaignCode: undefined,
  prevCampaignCode: undefined,
  isLeaderboard: undefined,
};

@Injectable({ providedIn: 'root' })
export class CampaignStore extends ComponentStore<CampaignState> {
  static initalState: CampaignState = initalState;
  constructor(private ngrxStore: Store<NgRxState>) {
    super(initalState);
  }

  readonly landing$ = this.select((state) => state.landing);
  readonly campaigns$ = this.select((state) => state.campaigns);
  readonly selectedBanner$ = this.select((state) => state.selectedBanner);
  readonly campaign$ = this.select((state) => state.campaign);
  readonly campaignCode$ = this.select((state) => state.campaignCode);
  readonly campaignType$ = this.select((state) => state.campaign);
  readonly statement$ = this.select((state) => state.statement);
  readonly rewardReceiveReq$ = this.select((state) => state.rewardReceiveReq);
  readonly rewardStatus$ = this.select((state) => state.rewardStatus);
  readonly couponId$ = this.select((state) => state.couponId);
  readonly perxToken$ = this.select((state) => state.perxToken);
  readonly historyScreen$ = this.select((state) => state.historyScreen);
  readonly getDefaultCoupon$ = this.select((state) => state.getDefaultCoupon);
  readonly prevCampaignCode$ = this.select((state) => state.prevCampaignCode);
  readonly isLeaderboard$ = this.select((state) => state.isLeaderboard);

  readonly initLanding = this.updater((state, landing: LandingDetail) => {
    this.ngrxStore.dispatch(
      Action.CampaignAction.SetLanding({ ...state, landing })
    );
    return { ...state, landing };
  });

  readonly addCampaigns = this.updater((state, campaigns: CampaignBanner[]) => {
    this.ngrxStore.dispatch(
      Action.CampaignAction.SetCampaigns({ ...state, campaigns })
    );
    return { ...state, campaigns };
  });

  readonly setSelectBanner = this.updater(
    (state, selectedBanner: CampaignBanner) => {
      this.ngrxStore.dispatch(
        Action.CampaignAction.SetSelectBanner({ ...state, selectedBanner })
      );
      return { ...state, selectedBanner };
    }
  );

  readonly setCampaign = this.updater((state, campaign: Campaign) => {
    this.ngrxStore.dispatch(
      Action.CampaignAction.SetCampaign({ ...state, campaign })
    );
    return { ...state, campaign };
  });

  readonly setStatement = this.updater((state, statement: CampaignTracking) => {
    this.ngrxStore.dispatch(
      Action.CampaignAction.SetStatement({ ...state, statement })
    );
    return { ...state, statement };
  });

  readonly setRewardReceiveRequest = this.updater(
    (state, rewardReceiveReq: RewardReceiveRequest) => {
      return { ...state, rewardReceiveReq };
    }
  );

  readonly setRewardStatus = this.updater(
    (state, rewardStatus: RewardStatus) => {
      return { ...state, rewardStatus };
    }
  );

  readonly setCouponId = this.updater((state, couponId: string) => {
    return { ...state, couponId };
  });

  readonly setGetDefaultCoupon = this.updater(
    (state, getDefaultCoupon: boolean) => {
      return { ...state, getDefaultCoupon };
    }
  );

  readonly setPerxToken = this.updater((state, perxToken: string) => {
    return { ...state, perxToken };
  });

  readonly reset = this.updater((state) => {
    this.ngrxStore.dispatch(Action.CampaignAction.ResetLanding());
    return { ...state, initalState };
  });

  readonly setHistoryScreen = this.updater(
    (state, historyScreen: HistoryScreen) => {
      return { ...state, historyScreen };
    }
  );

  readonly setCampaignCode = this.updater((state, campaignCode?: string) => {
    this.ngrxStore.dispatch(
      Action.CampaignAction.SetCampaignCode({ ...state, campaignCode })
    );
    return { ...state, campaignCode };
  });

  readonly setPrevCampaignCode = this.updater(
    (state, prevCampaignCode?: string) => {
      this.ngrxStore.dispatch(
        Action.CampaignAction.SetPrevCampaignCode({
          ...state,
          prevCampaignCode,
        })
      );
      return { ...state, prevCampaignCode };
    }
  );

  readonly setIsLeaderboard = this.updater((state, isLeaderboard?: boolean) => {
    this.ngrxStore.dispatch(
      Action.CampaignAction.SetIsLeaderboard({ ...state, isLeaderboard })
    );
    return { ...state, isLeaderboard };
  });

  readonly resetSelectedCampaign = this.updater((state) => {
    this.ngrxStore.dispatch(Action.CampaignAction.ResetSelectedCampaign());
    return {
      ...state,
      campaignCode: undefined,
      prevCampaignCode: undefined,
      isLeaderboard: undefined,
    };
  });
}
