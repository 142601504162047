import { Injectable } from '@angular/core';
import { CoreFetch } from './core/core';
import {
  PollingCouponRequest,
  PollingCouponResponse,
  RedeemRequest,
  RedeemResponse,
} from '../models';
import { Observable } from 'rxjs';
import { Endpoint } from '../configs/endpoint.config';
import { TransformTool } from '../tools/transform.tool';

@Injectable({
  providedIn: 'root',
})
export class TrackingRedeemFetchService extends CoreFetch {
  constructor() {
    super();
  }

  public redeem(query?: RedeemRequest): Observable<RedeemResponse> {
    return this.post<RedeemResponse>(Endpoint.TrackingRedeem.Redeem, query);
  }

  public pollingCoupon(
    query?: PollingCouponRequest
  ): Observable<PollingCouponResponse> {
    return this.get<PollingCouponResponse>(
      `${
        Endpoint.TrackingRedeem.PollingCoupon
      }${TransformTool.convertObjectToQueryString(query)}`
    );
  }
}
