import {
  Store,
  State,
  ScannedActionsSubject,
  ReducerObservable,
} from '@ngrx/store';
import { HttpService } from '../services/http.service';
import { NavigationService } from '../services/navigation.service';
import { HttpClientTestingModule } from '@angular/common/http/testing';
import { HttpClientModule } from '@angular/common/http';
import { RouterTestingModule } from '@angular/router/testing';
import { TranslateModule } from '@ngx-translate/core';
import { NO_ERRORS_SCHEMA } from '@angular/core';

import { DecimalFormatterPipe } from '../pipes/decimal-format/decimalFormatter.pipe';
import { SafeHtmlPipe } from '../pipes';

export const TEST_PROVIDERS = [
  Store,
  State,
  ScannedActionsSubject,
  ReducerObservable,
  HttpService,
  NavigationService,
  DecimalFormatterPipe,
  SafeHtmlPipe,
];

export const TEST_MODULES = [
  RouterTestingModule,
  HttpClientTestingModule,
  HttpClientModule,
  TranslateModule.forRoot(),
];

export const TEST_SCHEMAS = [NO_ERRORS_SCHEMA];
