/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, createReducer, on } from '@ngrx/store';

import { AppAction } from '../actions';
import { AppState } from '../states';

import * as dayjs from 'dayjs';

const initialState: AppState = {
  ready: false,
  timestamp: null as any,
};

const appReducer = createReducer(
  initialState,
  on(AppAction.SetStart, (state, { ready }) => ({
    ...state,
    ready,
    timestamp: dayjs().format(),
  }))
);

export function AppReducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}
