import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { ComponentStore } from '@ngrx/component-store';

import {
  AppLocalizationModule,
  AppFetchModule,
  AppNgrxModule,
  LoadingInterceptorProvider,
  AppNgxLottieModule,
} from '@ptp-campaign-spending/common';

import { InitializeProvider } from './initialize';

import { AppRoutingModule } from './modules';

import { AppComponent } from './app.component';
import { SafeHtmlPipe } from 'libs/common/src/lib/pipes';

const disableAnimations =
  !('animate' in document.documentElement) ||
  (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    HttpClientModule,
    AppLocalizationModule,
    AppFetchModule,
    AppNgrxModule,
    AppRoutingModule,
    AppNgxLottieModule,
  ],
  providers: [
    InitializeProvider,
    ComponentStore,
    LoadingInterceptorProvider,
    SafeHtmlPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
