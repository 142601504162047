import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalFormatter',
})
export class DecimalFormatterPipe implements PipeTransform {
  transform(value: any, format: any): any {
    try {
      const num = Number(value);
      const form = Number(format);
      if (Math.floor(num) === num) return num.toLocaleString();
      else if (num.toFixed(2).split('.')[1] == '00')
        return Number(num.toFixed(2).split('.')[0]).toLocaleString();
      else if (value != undefined && form === 1)
        return Number(num.toFixed(2)).toLocaleString();
      else if (
        value != undefined &&
        num.toFixed(2).split('.')[1].substring(1) == '0' &&
        form == 2
      )
        return Number(num.toFixed(2)).toLocaleString() + '0';
      else return Number(num.toFixed(2)).toLocaleString();
    } catch (error) {
      return value;
    }
  }
}
