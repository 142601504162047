import { createAction, props } from '@ngrx/store';
import {
  Campaign,
  CampaignBanner,
  CampaignTracking,
  LandingDetail,
} from '@ptp-campaign-spending/common/models';

export const SetLanding = createAction(
  'SetLanding',
  props<{ landing: LandingDetail }>()
);

export const SetCampaigns = createAction(
  'SetCampaigns',
  props<{ campaigns: CampaignBanner[] }>()
);

export const SetSelectBanner = createAction(
  'SetSelectBanner',
  props<{ selectedBanner: CampaignBanner }>()
);

export const SetCampaign = createAction(
  'SetCampaign',
  props<{ campaign: Campaign }>()
);

export const SetStatement = createAction(
  'SetStatement',
  props<{ statement: CampaignTracking }>()
);

export const ResetLanding = createAction('ResetLanding');

export const SetCampaignCode = createAction(
  'SetCampaignCode',
  props<{ campaignCode?: string }>()
);

export const SetPrevCampaignCode = createAction(
  'SetPrevCampaignCode',
  props<{ prevCampaignCode?: string }>()
);

export const SetIsLeaderboard = createAction(
  'SetIsLeaderboard',
  props<{ isLeaderboard?: boolean }>()
);

export const ResetSelectedCampaign = createAction('ResetSelectedCampaign');
