/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EMPTY } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { APP_SET_START } from '../actions/app.action';

@Injectable()
export class AppEffect {
  constructor(private actions: Actions) {}

  private appSetStartEffect = createEffect(() =>
    this.actions.pipe(
      ofType(APP_SET_START),
      tap((value) => console.log(`${APP_SET_START}:`, value)),
      mergeMap(() => EMPTY.pipe(map(() => null as any)))
    )
  );
}
