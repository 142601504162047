import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
} from '@angular/animations';
import { PAGE_NAME } from '@ptp-campaign-spending/common/configs';

export const slideInAnimation = trigger('routeAnimations', [
  transition(
    `${PAGE_NAME.TRACKING} => ${PAGE_NAME.LANDING}, 
    ${PAGE_NAME.TRACKING_HISTORY} => ${PAGE_NAME.TRACKING}, 
    ${PAGE_NAME.CAMPAIGN_DETAIL} => *, 
    ${PAGE_NAME.LEADERBOARD_REWARDS} => *, 
    ${PAGE_NAME.LEADERBOARD_INFO} => *, 
    ${PAGE_NAME.LEADERBOARD_HISTORY} => *, 
    ${PAGE_NAME.LEADERBOARD_PREVIOUS} => ${PAGE_NAME.LEADERBOARD}, 
    ${PAGE_NAME.LEADERBOARD} => ${PAGE_NAME.LANDING}, 
    ${PAGE_NAME.TRACKING} => ${PAGE_NAME.LEADERBOARD},
    ${PAGE_NAME.EARN_AND_BURN_DETAIL} => ${PAGE_NAME.EARN_AND_BURN}, 
    ${PAGE_NAME.EARN_AND_BURN_HISTORY} => ${PAGE_NAME.EARN_AND_BURN}, 
    ${PAGE_NAME.EARN_AND_BURN_REWARD} => *,
    ${PAGE_NAME.EARN_AND_BURN_TC} => *, 
    ${PAGE_NAME.EARN_AND_BURN} => ${PAGE_NAME.LANDING}`,
    slideTo('left')
  ),
  transition(
    `${PAGE_NAME.LANDING} => *, 
    ${PAGE_NAME.TRACKING} => *, 
    ${PAGE_NAME.LEADERBOARD} => *, 
    ${PAGE_NAME.LEADERBOARD_PREVIOUS} => ${PAGE_NAME.LEADERBOARD_REWARDS}, 
    ${PAGE_NAME.LEADERBOARD_PREVIOUS} => ${PAGE_NAME.LEADERBOARD_INFO}, 
    ${PAGE_NAME.LEADERBOARD_PREVIOUS} => ${PAGE_NAME.LEADERBOARD_HISTORY},  
    ${PAGE_NAME.LEADERBOARD_PREVIOUS} => ${PAGE_NAME.CAMPAIGN_DETAIL},
    ${PAGE_NAME.EARN_AND_BURN} => ${PAGE_NAME.EARN_AND_BURN_DETAIL},
    ${PAGE_NAME.EARN_AND_BURN} => ${PAGE_NAME.EARN_AND_BURN_HISTORY},
    ${PAGE_NAME.EARN_AND_BURN} => ${PAGE_NAME.EARN_AND_BURN_REWARD},
    ${PAGE_NAME.EARN_AND_BURN_DETAIL} => ${PAGE_NAME.EARN_AND_BURN_TC},
    ${PAGE_NAME.EARN_AND_BURN_HISTORY} => ${PAGE_NAME.EARN_AND_BURN_REWARD}`,
    slideTo('right')
  ),
]);

function slideTo(direction: string) {
  const optional = { optional: true };
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          [direction]: 0,
          width: '100%',
        }),
      ],
      optional
    ),
    query(':enter', [style({ [direction]: '-100%' })], optional),
    group([
      query(
        ':leave',
        [animate('600ms ease', style({ [direction]: '100%' }))],
        optional
      ),
      query(
        ':enter',
        [animate('600ms ease', style({ [direction]: '0%' }))],
        optional
      ),
    ]),
  ];
}
