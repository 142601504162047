import { ROUTE_PATH } from './path.config';

export interface EarnAndBurnMenuItem {
  name: EARN_AND_BURN_MENU;
  icon: string;
  text: string;
  route: ROUTE_PATH;
}

export enum REWARDS_TYPES {
  COUPON = 'COUPON',
  BIGPRIZE = 'BIGPRIZE',
}

export enum EARN_AND_BURN_MENU {
  INFORMATION = 'INFORMATION',
  MYCOUPON = 'MYCOUPON',
  HISTORY = 'HISTORY',
}

export const EarnAndBurnMenu: EarnAndBurnMenuItem[] = [
  {
    name: EARN_AND_BURN_MENU.INFORMATION,
    icon: 'assets/ico/information-menu-icon.png',
    text: 'SCREEN.EARN_AND_BURN.MENU.INFORMATION',
    route: ROUTE_PATH.EARN_AND_BURN_DETAIL,
  },
  {
    name: EARN_AND_BURN_MENU.MYCOUPON,
    icon: 'assets/ico/mycoupon-menu-icon.png',
    text: 'SCREEN.EARN_AND_BURN.MENU.MYCOUPON',
    route: ROUTE_PATH.EARN_AND_BURN_REWARD,
  },
  {
    name: EARN_AND_BURN_MENU.HISTORY,
    icon: 'assets/ico/history-menu-icon.png',
    text: 'SCREEN.EARN_AND_BURN.MENU.HISTORY',
    route: ROUTE_PATH.EARN_AND_BURN_HISTORY,
  },
];
