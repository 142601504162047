export enum WEB_COUPON_MODE {
  MY_COUPON = 'M',
  COUPON_DETAIL = 'D',
  CLOSE = 'close',
}
export enum COUPON_STATUS {
  RUNOUT = 0,
  AVAILABLE = 1,
  RECEIVED = 2,
}
export enum COUPON_TYPE {
  PENDING,
  UPCOMING,
  RECEIVED,
}
