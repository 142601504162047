/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, createReducer, on } from '@ngrx/store';
import { CampaignState } from '@ptp-campaign-spending/common/models';
import { CampaignStore } from '../../components/campaign.store';
import { CampaignAction } from '../actions';
import {
  ResetLanding,
  ResetSelectedCampaign,
  SetCampaignCode,
  SetIsLeaderboard,
  SetPrevCampaignCode,
} from '../actions/campaign.action';

const initialState: CampaignState = { ...CampaignStore?.initalState };

const campaignReducer = createReducer(
  initialState,
  on(CampaignAction.SetLanding, (state, { ...CampaignState }) => ({
    ...state,
    ...CampaignState,
  })),
  on(SetCampaignCode, (state, campaignCode) => ({ ...state, ...campaignCode })),
  on(SetPrevCampaignCode, (state, prevCampaignCode) => ({
    ...state,
    ...prevCampaignCode,
  })),
  on(SetIsLeaderboard, (state, isLeaderboard) => ({
    ...state,
    ...isLeaderboard,
  })),
  on(ResetSelectedCampaign, (state) => ({
    ...state,
    campaignCode: undefined,
    prevCampaignCode: undefined,
    isLeaderboard: undefined,
  })),
  on(ResetLanding, (state) => ({
    ...state,
  }))
);

export function CampaignReducer(
  state: CampaignState | undefined,
  action: Action
) {
  return campaignReducer(state, action);
}
