import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { Endpoint } from '../configs/endpoint.config';
import { BannerListRequest, BannerListResponse } from '../models';
import { TransformTool } from '../tools/transform.tool';
import {
  BE_CODE,
  ERROR_TYPE,
  FE_CODE,
  GA_FIREBASE_PARAMS_KEY,
  GA_EVENT_ACTION,
  MISSION_SCREEN,
} from '../configs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { CoreFetch } from './core/core';
import { CampaignService } from '../services/campaign.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class BannerFetchService extends CoreFetch {
  constructor(
    private errorHandler: ErrorHandlerService,
    private campaignService: CampaignService,
    private translateService: TranslateService
  ) {
    super();
  }

  public fetch(query: BannerListRequest): Observable<BannerListResponse> {
    return this.get<BannerListResponse>(
      `${Endpoint.Banner.List}${TransformTool.convertObjectToQueryString(
        query
      )}`
    ).pipe(
      map((resp: BannerListResponse) => {
        if (resp.code !== BE_CODE.SUCCESS) {
          if (query.campaignCode) {
            if (resp.code === BE_CODE.CAMPAIGN_EXPIRED) {
              this.errorHandler.setError(
                ERROR_TYPE.POPUP,
                {
                  screen: MISSION_SCREEN.LANDING,
                  errorMessage: resp.error_data?.error_message || '',
                  code: resp.code,
                  ga: {
                    event: {
                      action: GA_EVENT_ACTION.ERROR,
                      category: MISSION_SCREEN.LANDING,
                      label:
                        MISSION_SCREEN.LANDING +
                        (resp.code === BE_CODE.CAMPAIGN_EXPIRED
                          ? 'ExpireMission_Confirm'
                          : '_PopUpPermissionUserAccess'),
                    },
                    firebaseParam: [
                      {
                        key: GA_FIREBASE_PARAMS_KEY.PT_INFORMATION,
                        value: `campaignCode: ${query.campaignCode}`,
                      },
                    ],
                  },
                },
                {
                  error_title:
                    resp.error_data?.error_title ||
                    this.translateService.instant('ERROR.GENERIC.TITLE'),
                  error_message:
                    resp.error_data?.error_message ||
                    this.translateService.instant('ERROR.GENERIC.TEXT'),
                }
              );
            } else if (resp.code === BE_CODE.NOT_IN_SEGMENT) {
              this.errorHandler.setError(
                ERROR_TYPE.POPUP,
                {
                  screen: MISSION_SCREEN.LANDING,
                  errorMessage: resp.error_data?.error_message || '',
                  code: resp.code,
                  ga: {
                    event: {
                      action: GA_EVENT_ACTION.ERROR,
                      category: MISSION_SCREEN.API_ERROR,
                      label:
                        MISSION_SCREEN.LANDING + '_PopUpPermissionUserAccess',
                    },
                    firebaseParam: [
                      {
                        key: GA_FIREBASE_PARAMS_KEY.PT_INFORMATION,
                        value: `campaignCode: ${query.campaignCode}`,
                      },
                    ],
                  },
                },
                {
                  error_title:
                    resp.error_data?.error_title ||
                    this.translateService.instant('ERROR.GENERIC.TITLE'),
                  error_message:
                    resp.error_data?.error_message ||
                    this.translateService.instant('ERROR.GENERIC.TEXT'),
                }
              );
            } else {
              this.errorHandler.setError(
                ERROR_TYPE.FULLSCREEN_API,
                {
                  screen: MISSION_SCREEN.LANDING,
                  errorMessage: resp.error_data?.error_message || '',
                  code: resp.code,
                },
                {
                  error_title:
                    resp.error_data?.error_title ||
                    this.translateService.instant('ERROR.GENERIC.TITLE'),
                  error_message:
                    resp.error_data?.error_message ||
                    this.translateService.instant('ERROR.GENERIC.TEXT'),
                }
              );
            }
          } else {
            this.errorHandler.setError(ERROR_TYPE.FULLSCREEN_API, {
              screen: MISSION_SCREEN.LANDING,
              errorMessage: '',
              code: resp.code || FE_CODE.NO_BANNER_LIST,
              customMessage: `Error from BE code ${resp.code}`,
            });
          }
        }
        return resp;
      })
    );
  }
}
