import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../configs/endpoint.config';
import { AuthPerxRequest, AuthPerxResponse } from '../models';
import { CoreFetch } from './core/core';

@Injectable({ providedIn: 'root' })
export class PerxFetchService extends CoreFetch {
  constructor() {
    super();
  }

  public auth(data: AuthPerxRequest): Observable<AuthPerxResponse> {
    return this.post<AuthPerxResponse>(Endpoint.Perx.Auth, data);
  }
}
