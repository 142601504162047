import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as sanitizeHtml from 'sanitize-html';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: string, bypass = false): SafeHtml | string {
    const safeHtml = sanitizeHtml(value, {
      allowedAttributes: { span: ['style'] },
    });

    return bypass ? this.sanitizer.bypassSecurityTrustHtml(safeHtml) : safeHtml;
  }
}
