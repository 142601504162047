import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@ngrx/store';
import {
  map,
  filter,
  timeout,
  catchError,
  EMPTY,
  take,
  shareReplay,
  of,
  tap,
  startWith,
  throwError,
  Subject,
} from 'rxjs';
import { WebAuthFetchService } from '../fetches';
import { TokenGenerateResponse } from '../models';
import { UserInfoStore } from '../stored';
import { PAGE_NAME } from '../configs';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  token$ = this.extractParam('authorizationCode');
  campaignCode$ = this.extractParam('campaignCode');
  page$ = this.extractParam('page');
  rewardId$ = this.extractParam('rewardId');

  channel: string | undefined;
  product: string | undefined;

  verifyToken: string | undefined;
  isOffline = false;
  isOffline$ = new Subject<boolean>();
  webviewToken$ = this.extractParam('webviewToken');
  authorizationCodeParam: string | undefined;
  webviewTokenParam: string | undefined;
  isRefreshToken = false;
  noPopupParam = false;
  page: PAGE_NAME | undefined;

  constructor(
    private route: ActivatedRoute,
    private webAuthFetchService: WebAuthFetchService,
    private userInfoStore: UserInfoStore,
    private userState: State<UserInfoStore>
  ) {}

  /**
   * รอค่าจาก Query
   * @param param ชื่อ Parameter
   * @param time เวลา timeout ที่จะรอ QueryParameter
   * @returns
   */
  private extractParam(param: string, time = 400) {
    return this.route.queryParams.pipe(
      map((params) => params[param]),
      filter((param) => Boolean(param)),
      timeout(time),
      catchError((e) => {
        return EMPTY;
      }),
      take(1),
      shareReplay(1)
    );
  }

  /**
   * เป็น api เส้นแรกที่เรียก เพื่อ verify token
   * @param token token ที่ได้จาก QueryParam
   * @returns Observable จาก verify
   */
  public httpVerifyToken() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const webviewToken = urlParams.get('webviewToken');
    this.webviewTokenParam = webviewToken || '';
    this.noPopupParam = Boolean(urlParams.get('noPopup'));
    this.authorizationCodeParam =
      urlParams.get('authorizationCode') ||
      this.userState?.value?.UserInfo?.authCode;
    this.channel = urlParams.get('channel') || undefined;
    this.product = urlParams.get('product') || undefined;

    return this.webAuthFetchService
      .generate({
        channel: this.channel,
        product: this.product,
      })
      ?.pipe(
        startWith(undefined),
        tap((resp: TokenGenerateResponse) => {
          if (resp && resp.data) {
            this.userInfoStore.initUserInfo(resp.data);
            this.verifyToken = resp.data.access_token;
            this.userInfoStore.setAuthorizationCode(
              this.authorizationCodeParam
            );
          } else {
            throwError(() => new Error('no token'));
          }
        })
      );
  }

  /**
   * ดึงข้อมูลจากเส้น verify ถ้าเคยดึงแล้ว ให้ใช้ cache แทน
   * @param token token ที่ได้จาก QueryParam
   * @returns Observable จาก verify
   */
  getVerifyToken() {
    if (this.verifyToken) {
      return of(this.verifyToken);
    }
    return this.httpVerifyToken();
  }
}
