import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ENVIRONMENT_MODE } from '@ptp-campaign-spending/common/configs';
import { environment } from 'environment';
import { ChildrenOutletContexts } from '@angular/router';
import { slideInAnimation } from 'libs/ui/src/lib/navigation/route-animation';

declare let VConsole: any;
@Component({
  selector: 'ptp-campaign-spending-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit {
  public version: string | undefined;
  public ENV: ENVIRONMENT_MODE | undefined;

  constructor(private contexts: ChildrenOutletContexts) {}

  ngOnInit(): void {
    if (!environment.production) {
      this.version = environment.version;

      try {
        new VConsole({
          theme: 'dark',
          defaultPlugins: ['network', 'system', 'element', 'storage'],
        });
        const vConsole = document.querySelector('.vc-switch');
        if (vConsole) {
          vConsole.innerHTML = this.version;
        }
      } catch {
        console.log('VConsole not responding');
      }
    }
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
      'animation'
    ];
  }
}
