export enum ROUTE_PATH {
  LANDING = '/',
  TRACKING = '/tracking',
  TRACKING_HISTORY = '/tracking/history',
  TRACKING_DETAIL = '/tracking/detail',
  LEADERBOARD = '/leaderboard',
  LEADERBOARD_INFO = '/leaderboard/info',
  LEADERBOARD_REWARDS = '/leaderboard/rewards',
  LEADERBOARD_PREVIOUS = '/leaderboard/previous',
  LEADERBOARD_HISTORY = '/leaderboard/history',
  LEADERBOARD_TC = '/leaderboard/termAndCon',
  EARN_AND_BURN = '/earnAndBurn',
  EARN_AND_BURN_DETAIL = '/earnAndBurn/detail',
  EARN_AND_BURN_TC = '/earnAndBurn/termAndCon',
  EARN_AND_BURN_REWARD = '/earnAndBurn/reward',
  EARN_AND_BURN_HISTORY = '/earnAndBurn/ebhistory',
}

export enum MISSION_SCREEN {
  LANDING = 'MissionLanding',
  TRACKING = 'MissionTracking',
  TRACKING_HISTORY = 'MissionTrackingHistory',
  TRACKING_DETAIL = 'MissionTrackingT&C',
  TRACKING_LEADERBOARD = 'LeaderboardTracking',
  TRACKING_LEADERBOARD_REWARDS = 'LeaderboardTrackingTotalRewards',
  TRACKING_LEADERBOARD_HISTORY = 'LeaderboardTrackingReferralHistory',
  TRACKING_LEADERBOARD_INFO = 'LeaderboardTrackingMissionInfo',
  TRACKING_LEADERBOARD_TC = 'LeaderboardTrackingT&C',
  TRACKING_EARN_AND_BURN_DETAIL = 'MissionDetail',
  TRACKING_EARN_AND_BURN_TC = 'MissionT&C',
  TRACKING_EARN_AND_BURN_REWARD = 'MissionRewardDetail',
  TRACKING_EARN_AND_BURN_HISTORY = 'MissionHistory',
  COUPON = 'coupon',
  PERX = 'perx',
  FULLSCREEN_ERROR = 'MissionScreenError',
  API_ERROR = 'MissionAPIError',
  COUPON_TIMEOUT = 'MissionWaitingCoupon',
}

export enum PAGE_NAME {
  LANDING = 'landing',
  TRACKING = 'tracking',
  TRACKING_HISTORY = 'history',
  COUPON = 'coupon',
  PERX = 'perx',
  CAMPAIGN_DETAIL = 'termAndCon',
  LEADERBOARD = 'leaderboard',
  LEADERBOARD_REWARDS = 'rewards',
  LEADERBOARD_PREVIOUS = 'previous',
  LEADERBOARD_INFO = 'info',
  LEADERBOARD_HISTORY = 'history',
  EARN_AND_BURN = 'earnburn',
  EARN_AND_BURN_DETAIL = 'detail',
  EARN_AND_BURN_TC = 'termAndCon',
  EARN_AND_BURN_REWARD = 'reward',
  EARN_AND_BURN_HISTORY = 'ebhistory',
}

export enum EXTERNAL_PATH {
  REGISTER_PLAYCARD = 'paotang://paotang.co.th/mission-base.webview.register.playcard.deeplink',
}

export enum FLOW_NAME {
  PTP_REFERRAL_LANDING = 'PTP_TO_REFERRAL_LANDING',
}

export enum DESTINATION {
  BACK = 'BACK',
  COUPON = 'COUPON',
  PTP_BILLER_LIST = 'PTP_BILLER_LIST',
  PTP_TOPUP_LIST = 'PTP_TOPUP_LIST',
  PTP_SCAN_QR = 'PTP_SCAN_QR',
  PTP_PLAYCARD_HOME = 'PTP_PLAYCARD_HOME',
  REFERRAL = 'REFERRAL',
}

export enum COMMAND {
  ROUTING = 'ROUTING',
  SHARE = 'SHARE',
}
