import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  EventInterceptorProvider,
  LoggingInterceptorProvider,
} from '../fetches';

import { environment } from 'environment';

@NgModule({
  imports: [CommonModule],
  providers: [
    EventInterceptorProvider,
    !environment.production ? LoggingInterceptorProvider : [],
  ],
})
export class AppFetchModule {}
