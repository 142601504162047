/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';

import * as dayjs from 'dayjs';

@Pipe({
  name: 'xdate',
})
export class XdatePipe implements PipeTransform {
  transform(
    value: any,
    format: string = 'DD MMM BB HH:mm',
    prefix: string = '',
    ariaLabel?: boolean
  ): string {
    if (Array.isArray(value)) {
      const sameMonth =
        dayjs(value[0]).format('MM') === dayjs(value[1]).format('MM');
      const sameYear =
        dayjs(value[0]).format('BBBB') === dayjs(value[1]).format('BBBB');

      if (ariaLabel) {
        const startDate = dayjs(value[0]).format(
          sameYear && sameMonth ? 'D' : sameYear ? 'D MMMM' : 'D MMMM BB'
        );
        const endDate = dayjs(value[1]).format(format);

        return `${this.isAndroid() ? 'วันที่' : ''}${startDate} ${
          sameYear && sameMonth ? '-' : 'ถึง'
        } ${endDate}`;
      } else {
        const startDate = dayjs(value[0]).format(
          sameYear && sameMonth ? 'D' : sameYear ? 'D MMM' : 'D MMM BB'
        );
        const endDate = dayjs(value[1]).format(format);

        return `${startDate} - ${endDate}`;
      }
    } else {
      if (typeof value === 'string' && value.split(':').length >= 3) {
        return dayjs(value, 'YYYY-MM-DD HH:mm').format(format);
      } else if (typeof value === 'string' && value.split('-').length >= 3) {
        return dayjs(value, 'YYYY-MM-DD').format(format);
      } else {
        return prefix;
      }
    }
  }

  private isAndroid() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1;
  }
}
