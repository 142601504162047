import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceText',
})
export class ReplaceTextPipe implements PipeTransform {
  transform(
    value: string,
    to: string = ' ',
    strKey: string = '[',
    endKey: string = ']',
    maxLen: number = 6
  ): string {
    const substr = value.substring(
      strKey ? value.indexOf(strKey) + 1 : 0,
      endKey ? value.indexOf(endKey) : value.length - 1
    );

    if (substr.length > maxLen || strKey.length === 0) {
      return value;
    }

    return value.trim().replace(substr, substr.split('').join(to));
  }
}
