import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Endpoint } from '../configs/endpoint.config';
import {
  CampaignRequest,
  CampaignResponse,
  CampaignStatementRequest,
  CampaignStatementResponse,
} from '../models';
import { TransformTool } from '../tools/transform.tool';
import {
  BE_CODE,
  ERROR_TYPE,
  GA_FIREBASE_PARAMS_KEY,
  GA_EVENT_ACTION,
  MISSION_SCREEN,
} from '../configs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { CoreFetch } from './core/core';
import { CampaignService } from '../services/campaign.service';

@Injectable({ providedIn: 'root' })
export class CampaignFetchService extends CoreFetch {
  constructor(
    private errorHandler: ErrorHandlerService,
    private campaignService: CampaignService
  ) {
    super();
  }

  public detail(
    campaignCode: string,
    query: CampaignRequest
  ): Observable<CampaignResponse> {
    return this.get<CampaignResponse>(
      `${
        Endpoint.Campaign.Detail
      }/${campaignCode}${TransformTool.convertObjectToQueryString(query)}`
    ).pipe(
      map((resp: any) => {
        if (resp.code !== BE_CODE.SUCCESS) {
          if (resp.code === BE_CODE.CAMPAIGN_EXPIRED) {
            this.errorHandler.setError(
              ERROR_TYPE.POPUP,
              {
                screen: MISSION_SCREEN.TRACKING,
                errorMessage: resp.error_data?.error_message || '',
                code: resp.code,
                ga: {
                  event: {
                    action: GA_EVENT_ACTION.ERROR,
                    category: MISSION_SCREEN.LANDING,
                    label: MISSION_SCREEN.LANDING + 'ExpireMission_Confirm',
                  },
                  firebaseParam: [
                    {
                      key: GA_FIREBASE_PARAMS_KEY.PT_INFORMATION,
                      value: `campaignCode: ${campaignCode}`,
                    },
                  ],
                },
              },
              resp.error_data
            );
          } else {
            this.errorHandler.setError(
              ERROR_TYPE.FULLSCREEN_API,
              {
                screen: MISSION_SCREEN.TRACKING,
                errorMessage: resp.error_data?.error_message || '',
                code: resp.code,
              },
              resp.error_data
            );
          }
        }
        return resp;
      })
    );
  }

  public statement(
    campaignCode: string,
    query: CampaignStatementRequest
  ): Observable<CampaignStatementResponse> {
    return this.get<CampaignStatementResponse>(
      `${
        Endpoint.Campaign.Statement
      }/${campaignCode}${TransformTool.convertObjectToQueryString(query)}`
    ).pipe(
      map((resp: CampaignStatementResponse) => {
        if (resp.code !== BE_CODE.SUCCESS) {
          this.errorHandler.setError(
            ERROR_TYPE.FULLSCREEN_API,
            {
              screen: MISSION_SCREEN.TRACKING,
              errorMessage: resp.error_data?.error_message || '',
              code: resp.code,
            },
            resp.error_data
          );
        }
        return resp;
      })
    );
  }
}
