import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { Endpoint } from '../configs/endpoint.config';
import { BE_CODE, ERROR_TYPE, MISSION_SCREEN } from '../configs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { CoreFetch } from './core/core';
import {
  ValidatePlayCardRequest,
  ValidatePlayCardResponse,
} from '../models/validate-playcard.interface';

@Injectable({ providedIn: 'root' })
export class ValidateFetchService extends CoreFetch {
  constructor(private errorHandler: ErrorHandlerService) {
    super();
  }

  public validate(
    query: ValidatePlayCardRequest
  ): Observable<ValidatePlayCardResponse> {
    return this.get<ValidatePlayCardResponse>(
      `${Endpoint.Validate.Playcard}/${query.campaignCode}`
    ).pipe(
      map((resp: ValidatePlayCardResponse) => {
        if (resp.code !== BE_CODE.SUCCESS) {
          this.errorHandler.setError(
            ERROR_TYPE.POPUP,
            {
              screen: MISSION_SCREEN.TRACKING,
              errorMessage: `${resp.error_data?.error_title} ${resp.error_data?.error_message}`,
              code: resp.code,
            },
            resp.error_data,
            resp.code === BE_CODE.LOAD_FAILED ? false : true
          );
        }
        return resp;
      })
    );
  }
}
