import { Action, createReducer, on } from '@ngrx/store';
import { LeaderboardState } from './../../../models/leaderboard.interface';
import { LeaderboardStore } from './../../components/leaderboard.store';
import { LeaderboardAction } from '../actions';
import { Reset } from '../actions/leaderboard.action';

const initialState: LeaderboardState = { ...LeaderboardStore?.initialState };

const leaderboardReducer = createReducer(
  initialState,
  on(LeaderboardAction.SetLeaderboard, (state, { ...LeaderboardState }) => ({
    ...state,
    ...LeaderboardState,
  })),
  on(Reset, () => initialState)
);

export function LeaderboardReducer(
  state: LeaderboardState | undefined,
  action: Action
) {
  return leaderboardReducer(state, action);
}
