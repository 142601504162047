import { environment } from 'environment';

export const Endpoint = {
  Coupon: {
    Detail: `${environment.service.host}/api/v1/web-coupon-api/coupon/detail`,
  },
  Banner: {
    List: `${environment.service.host}/api/v1/web-banner-api/banner/list`,
  },
  Campaign: {
    Detail: `${environment.service.host}/api/v1/web-campaign-api/campaign/detail`,
    Statement: `${environment.service.host}/api/v1/web-campaign-statement-api/campaign/statement`,
  },
  CheckIn: {
    Process: `${environment.service.host}/api/v1/web-checkIn-api/process`,
    Artwork: `${environment.service.host}/api/v1/orch-mbcp-check-in-url-artworks-inquiry/{campaignCode}`,
  },
  Reward: {
    Status: `${environment.service.host}/api/v1/web-mission-reward-api/reward/status`,
    Receive: `${environment.service.host}/api/v1/web-get-coupon-api/reward/receive`,
    Popup: `${environment.service.host}/api/v1/web-mission-reward-api/reward/popup`,
  },
  Perx: {
    Auth: `${environment.service.host}/api/v1/web-perx-auth-api/perx/auth`,
  },
  Validate: {
    Playcard: `${environment.service.host}/api/v1/orch-mbcp-card-validator/campaign-codes`,
  },
  Share: {
    Info: `${environment.service.host}/api/v1/web-share-mission-generate-api/share/info`,
  },
  Leaderboard: {
    Detail: `${environment.service.host}/api/v1/orch-mbcp-leaderboard-inquiry/{campaignCode}/detail`,
    History: `${environment.service.host}/api/v1/orch-mbcp-referral-code-inquiry/history`,
    PreviousRankPopup: `${environment.service.host}/api/v1/orch-mbcp-leaderboard-inquiry/{campaignCode}/reward-popup/seen`,
  },
  MissionTracking: {
    List: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-manage/list`,
    PointHistories: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-manage/point-histories`,
    PointBalance: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-manage/point-balance`,
    Rewards: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-manage/rewards`,
  },
  TrackingRedeem: {
    PollingCoupon: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-redeem/polling-coupon`,
    Redeem: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-redeem/redeem`,
  },
  Auth: {
    Generate: `${environment.service.host}/api/v1/web-auth-api/token/generate`,
  },
};
