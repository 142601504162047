import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { Endpoint } from '../configs/endpoint.config';
import {
  CheckInGetArtworkRequest,
  CheckInGetArtworkResponse,
  CheckInProcessRequest,
  CheckInProcessResponse,
} from '../models';

import {
  BE_CODE,
  ERROR_TYPE,
  GA_FIREBASE_PARAMS_KEY,
  GA_EVENT_ACTION,
  MISSION_SCREEN,
} from '../configs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { CoreFetch } from './core/core';

@Injectable({ providedIn: 'root' })
export class CheckInFetchService extends CoreFetch {
  constructor(private errorHandler: ErrorHandlerService) {
    super();
  }

  public process(
    query: CheckInProcessRequest
  ): Observable<CheckInProcessResponse> {
    return this.post<CheckInProcessResponse>(
      Endpoint.CheckIn.Process,
      query
    ).pipe(
      map((resp: any) => {
        if (resp.code !== BE_CODE.SUCCESS) {
          if (resp.code === BE_CODE.CAMPAIGN_EXPIRED) {
            this.errorHandler.setError(
              ERROR_TYPE.POPUP,
              {
                screen: MISSION_SCREEN.TRACKING,
                errorMessage: resp.error_data?.error_message || '',
                code: resp.code,
                ga: {
                  event: {
                    action: GA_EVENT_ACTION.ERROR,
                    category: MISSION_SCREEN.LANDING,
                    label: MISSION_SCREEN.LANDING + 'ExpireMission_Confirm',
                  },
                  firebaseParam: [
                    {
                      key: GA_FIREBASE_PARAMS_KEY.PT_INFORMATION,
                      value: `campaignCode: ${query.campaignCode}`,
                    },
                  ],
                },
              },
              resp.error_data
            );
          } else {
            this.errorHandler.setError(
              ERROR_TYPE.POPUP,
              {
                screen: MISSION_SCREEN.TRACKING,
                errorMessage: resp.error_data?.error_message || '',
                code: resp.code,
                ga: {
                  event: {
                    action: GA_EVENT_ACTION.ERROR,
                    category: MISSION_SCREEN.API_ERROR,
                    label: `${resp.code}//${resp.message}`,
                  },
                  firebaseParam: [],
                },
              },
              resp.error_data
            );
          }
        }
        return resp;
      })
    );
  }

  public getArtwork(
    query: CheckInGetArtworkRequest
  ): Observable<CheckInGetArtworkResponse> {
    return this.get<CheckInGetArtworkResponse>(
      Endpoint.CheckIn.Artwork.replace('{campaignCode}', query.campaignCode)
    );
  }
}
