export enum ENVIRONMENT_MODE {
  LOCAL = 'local',
  DEV = 'dev',
  SIT = 'sit',
  UAT = 'uat',
  PROD = 'prod',
}

export enum CHANNEL {
  PT = 'PT',
}

export enum PRODUCT {
  PTP = 'PW',
}
