import { APP_INITIALIZER, Injectable, Injector, Provider } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { Action, NgRxState } from '@ptp-campaign-spending/common';

import { environment } from '../environments/environment';
import ServiceLocator from 'libs/common/src/lib/services/service-locator.service';

export function initialize(
  initializeService: InitializeService
): () => Observable<void> {
  return () => initializeService.initialize();
}

@Injectable({
  providedIn: 'root',
})
export class InitializeService {
  constructor(private ngrxStore: Store<NgRxState>) {}

  public initialize(): Observable<void> {
    return new Observable((subscriber) => {
      if (!environment.production)
        console.log(`App Start!. (${environment.mode})`);

      this.ngrxStore.dispatch(Action.AppAction.SetStart({ ready: true }));

      subscriber.complete();
    });
  }
}

function _setupServiceLocator(injector: Injector): () => Promise<any> {
  return async () => {
    ServiceLocator.rootInjector = injector;
  };
}

const SetupServiceLocator: Provider = {
  provide: APP_INITIALIZER,
  useFactory: _setupServiceLocator,
  deps: [Injector],
  multi: true,
};

const SetFactoryProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initialize,
  deps: [InitializeService],
  multi: true,
};

export const InitializeProvider: Provider[] = [SetupServiceLocator];
