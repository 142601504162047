export enum ERROR_TYPE {
  POPUP,
  FULLSCREEN_CONNECTION,
  FULLSCREEN_API,
  ONLOAD,
  INCOMING,
}

export enum BE_CODE {
  SUCCESS = 1000,
  EXPIRED = 2000,
  NO_INTERNET = 3001,
  LOAD_FAILED = 3002,
  NO_CDI = 3003,
  CAMPAIGN_EXPIRED = 3004,
  NO_COUPON_LEFT = 3005,
  MAINTENANCE = 3006,
  QUIZ_FAILED = 3007,
  NOT_IN_SEGMENT = 3009,
  OUT_OF_COUPON = 3010,
  PERX_FAILED = 4000,
  GENERIC = 5000,
}

export enum FE_CODE {
  OFFLINE = 'FEC001',
  NO_AUTHORIZATION_CODE = 'FEC002',
  LOAD_PDF_FAILED = 'FEC003',
  NO_CAMPAIGN = 'FEC004',
  TIMEOUT = 'FEC006',
  NO_BANNER_LIST = 'FEC007',
}
