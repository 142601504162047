import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { Endpoint } from '../configs/endpoint.config';
import {
  RewardReceiveRequest,
  RewardReceiveResponse,
  RewardStatusRequest,
  RewardStatusResponse,
} from '../models';
import { TransformTool } from '../tools/transform.tool';
import {
  BE_CODE,
  ERROR_TYPE,
  FE_CODE,
  GA_FIREBASE_PARAMS_KEY,
  GA_EVENT_ACTION,
  MISSION_SCREEN,
} from '../configs';
import { ErrorHandlerService } from '../services/error-handler.service';
import {
  RewardPopupRequest,
  RewardPopupResponse,
} from '../models/reward-popup.interface';
import { CoreFetch } from './core/core';

@Injectable({ providedIn: 'root' })
export class RewardFetchService extends CoreFetch {
  constructor(private errorHandler: ErrorHandlerService) {
    super();
  }

  public status(query: RewardStatusRequest): Observable<RewardStatusResponse> {
    return this.get<RewardStatusResponse>(
      `${Endpoint.Reward.Status}/${
        query.campaignCode
      }${TransformTool.convertObjectToQueryString(query)}`
    ).pipe(
      map((resp: any) => {
        if (resp.code !== BE_CODE.SUCCESS) {
          this.errorHandler.setError(
            ERROR_TYPE.POPUP,
            {
              screen: MISSION_SCREEN.TRACKING,
              errorMessage: `${resp.error_data?.error_title} ${resp.error_data?.error_message}`,
              code: resp.code,
            },
            resp.error_data,
            resp.code === BE_CODE.NO_COUPON_LEFT ? false : true
          );
        }
        return resp;
      })
    );
  }

  public receive(
    query: RewardReceiveRequest
  ): Observable<RewardReceiveResponse> {
    return this.post<RewardReceiveResponse>(
      Endpoint.Reward.Receive,
      query
    ).pipe(
      map((resp: RewardReceiveResponse) => {
        if (resp.code !== BE_CODE.SUCCESS) {
          if (resp.code === BE_CODE.CAMPAIGN_EXPIRED) {
            if (query.campaignCode) {
              this.errorHandler.setError(
                ERROR_TYPE.POPUP,
                {
                  screen: MISSION_SCREEN.LANDING,
                  errorMessage: resp.error_data?.error_message || '',
                  code: resp.code,
                  ga: {
                    event: {
                      action: GA_EVENT_ACTION.ERROR,
                      category: MISSION_SCREEN.LANDING,
                      label: MISSION_SCREEN.LANDING + 'ExpireMission_Confirm',
                    },
                    firebaseParam: [
                      {
                        key: GA_FIREBASE_PARAMS_KEY.PT_INFORMATION,
                        value: `campaignCode: ${query.campaignCode}`,
                      },
                    ],
                  },
                },
                resp.error_data
              );
            } else {
              this.errorHandler.setError(ERROR_TYPE.POPUP, {
                screen: MISSION_SCREEN.TRACKING,
                errorMessage: '',
                code: FE_CODE.NO_CAMPAIGN,
                customMessage: `Error from BE code ${resp.code}`,
              });
            }
          } else {
            this.errorHandler.setError(
              ERROR_TYPE.POPUP,
              {
                screen: MISSION_SCREEN.LANDING,
                errorMessage: resp.error_data?.error_message || '',
                code: resp.code,
              },
              resp.error_data
            );
          }
        }
        return resp;
      })
    );
  }

  public popup(query: RewardPopupRequest): Observable<RewardPopupResponse> {
    return this.post<RewardPopupResponse>(Endpoint.Reward.Popup, query);
  }
}
